import React from 'react';
import HomePage from "./HomePage";
import getRedisClient from '../utils/redis';
import getRedisClientLocal from '../utils/localredis';
import errorLogApi from '../components/custom/ErrorLogApi';
import axios from "axios";



function Home({ products }) {
  

  return (
    <HomePage />
  );
}

export async function getServerSideProps(context) {

  const { req } = context;
  // Helper function to extract the domain name
  function sanitizeHostname(host) {
    const hostname = new URL(`https://${host}`).hostname;
    return hostname;
  }

  const logError = (endpoint, error) => {
    const responseData = error.response?.data ?? '';
    errorLogApi({
      job_id: "",
      endpoint: endpoint,
      payload: '',
      responseData: responseData,
      errorMsg: error
    });
  };

  
  const hostname = req.headers.host === "localhost:3000" ? process.env.NEXT_PUBLIC_WEB_HOST : sanitizeHostname(req.headers.host);

  const redishostname = sanitizeHostname(req.headers.host);
  const redis = redishostname.startsWith('localhost') ? getRedisClientLocal() : getRedisClient();


  const previewUrl = req.headers.host === "localhost:3000"
    ? "https://preview.jpencil.in/?pid=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqYm9faWQiOjIzMywic2l0ZV9zdGF0dXMiOjAsImlhdCI6MTcxNDY0MzI4MX0.QXsuG6PL2rBZP92YZMTeMRnO3at8Sfq2gOf22rG6jeg"
    : req.url;

  const urlObj = new URL(previewUrl, `http://${hostname}`);
  const pid = urlObj.searchParams.get('pid');
  
  if (hostname.startsWith('preview.')) {
    const innerJson = JSON.stringify({
      domain: hostname,
      token: pid
    });

    const payload = {
      data: innerJson
    };


    try {
      const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      return { props: { products: response.data } };
    } catch (error) {
      // To capture logs
      logError('getJbo', error);
    }
  }

  try {
  
    const cachedProducts = await redis.get(hostname);
console.log("cached products ")
    if (cachedProducts) {
      return { props: { products: JSON.parse(cachedProducts) } };
    }
    console.log("failed cached products ")
    // If no cache, make an API call for no cached Products
    const innerJson = JSON.stringify({ domain: hostname });
    const payload = {
      data: JSON.stringify({ domain: hostname })
    };
    console.log("before getJBo call starts");
    
    try {
console.log("getJBo call starts");
      const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
        headers: { 'Content-Type': 'application/json' },
        timeout: 30000
      });

      if (response.data && Object.keys(response.data).length) {
        
        return { props: { products: response.data } };
      }

    }
    catch(e){
     
      // To capture logs
      logError('getJbo', e);
    
      return { props: { products: [] } };
    }
    
  }  catch (error) {
    console.log("error console checks success");
    const innerJson = JSON.stringify({ domain: hostname });
    const payload = {
      data: JSON.stringify({ domain: hostname })
    };
    console.log("get Jbo works");
  
    const response = await axios.post(`https://${hostname}/api/user/getJbo`, payload, {
      headers: { 'Content-Type': 'application/json' }
    });

    if (response.data && Object.keys(response.data).length) {
      console.log("getJbo success");
      return { props: { products: response.data } };
    }
    else{
      console.log("error")
      logError('getJbo', error);
    }

  
    
    return { props: { products: [] } };
  }

}


export default Home;
